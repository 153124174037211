var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('div',[_c('b-loading',{class:[{ 'is-relative': !this.isFullPageLoading }, 'panel-content'],attrs:{"is-full-page":_vm.isFullPageLoading,"can-cancel":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFullPageLoading || !_vm.loading),expression:"isFullPageLoading || !loading"}],staticClass:"pt-3"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.generatePDF.apply(null, arguments)}}},[_c('div',{staticClass:"columns is-desktop"},[_c('div',{staticClass:"column is-3-desktop is-12"},[_c('UIInput',{attrs:{"id":"exs-ano","type":"select","options":_vm.years,"label":"Ano","placeholder":"Ano","validation":_vm.$v.form.ano,"error":{
								required: '*Campo obrigatório.'
							},"required":""},model:{value:(_vm.form.ano),callback:function ($$v) {_vm.$set(_vm.form, "ano", $$v)},expression:"form.ano"}})],1),_c('div',{staticClass:"column is-offset-1-desktop is-3-desktop is-12"},[_c('UIInput',{attrs:{"id":"exs-semestre","type":"select","options":[
								{ label: 'Primeiro', value: '1' },
								{ label: 'Segundo', value: '2' }
							],"label":"Semestre","placeholder":"Semestre","validation":_vm.$v.form.semestre,"error":{
								required: '*Campo obrigatório.'
							},"required":""},model:{value:(_vm.form.semestre),callback:function ($$v) {_vm.$set(_vm.form, "semestre", $$v)},expression:"form.semestre"}})],1),_c('div',{staticClass:"column is-offset-1-desktop is-4-desktop is-12 is-flex is-justify-content-center"},[_c('button',{staticClass:"my-2 button is-primary is-rounded",attrs:{"type":"submit"}},[_vm._v(" Gerar Extrato ")])])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }